<ion-app>
  <ion-split-pane [when]="showSplitPane()" contentId="main-content">
    <ion-menu contentId="main-content" type="overlay" [disabled]="!sideMenuEnabled">
      <ion-content>
        <img
          class="corderoLogo"
          src="./assets/images/ORIGINAL/AvecSlogan/CORDERO_LOGO_OFFICIEL_COULEUR_RGB.jpg" />
        <ion-list id="inbox-list">
          <ion-list-header *ngIf="authProvider?.user !== null" class="helloMessage"
            >Hello {{ authProvider?.user.firstName }}, I'm Jarvics, how may I assist you ?
          </ion-list-header>
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item
              *ngIf="!p.parent"
              routerDirection="root"
              [routerLink]="[p.url]"
              [queryParams]="p.query ? p.query : null"
              lines="none"
              detail="false"
              routerLinkActive="selected"
              [routerLinkActiveOptions]="{ exact: true }"
              debounce="500"
              id="{{ p.data_cy }}">
              <div class="icon-badge">
                <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              </div>
              <ion-label class="tab">{{ p.title }}</ion-label>
            </ion-item>
            <ion-accordion-group *ngIf="p.parent">
              <ion-accordion (click)="accordionClicked($event)" value="first">
                <ion-item slot="header" lines="none" detail="false" id="{{ p.parent.data_cy }}">
                  <div class="icon-badge">
                    <ion-badge
                      *ngIf="p.parent.alerts && notificationsService.getNotifications()"
                      class="main-badge"
                      color="danger"
                      >{{ notificationsService.getNotifications() }}</ion-badge
                    >
                    <ion-icon
                      slot="start"
                      [ios]="p.parent.icon + '-outline'"
                      [md]="p.parent.icon + '-sharp'"></ion-icon>
                  </div>
                  <ion-label class="tab">{{ p.parent.title }}</ion-label>
                </ion-item>
                <div slot="content">
                  <ion-item
                    (click)="childItemClicked($event)"
                    *ngFor="let c of p.children"
                    routerDirection="root"
                    [routerLink]="[c.url]"
                    lines="none"
                    detail="false"
                    routerLinkActive="selected"
                    debounce="500"
                    id="{{ c.data_cy }}">
                    <ion-label class="tab">{{ c.title }}</ion-label>
                    <ion-badge
                      *ngIf="c.alerts && notificationsService.getNotifications(c.messageType)"
                      color="danger"
                      >{{ notificationsService.getNotifications(c.messageType) }}</ion-badge
                    >
                  </ion-item>
                </div>
              </ion-accordion>
            </ion-accordion-group>
          </ion-menu-toggle>

          <ion-list-header class="dashboardMenu">Dashboards</ion-list-header>
          <ion-menu-toggle auto-hide="false" *ngFor="let p of dashboards; let i = index">
            <ion-item
              routerDirection="root"
              [routerLink]="[p.url]"
              [queryParams]="p.query ? p.query : null"
              lines="none"
              detail="false"
              routerLinkActive="selected"
              [routerLinkActiveOptions]="{ exact: true }"
              debounce="500">
              <div class="icon-badge">
                <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              </div>
              <ion-label class="tab">{{ p.title }}</ion-label>
              <ion-badge *ngIf="p.alerts && notificationsService.getNotifications(p.messageType)" color="warning">
                {{  notificationsService.getNotifications(p.messageType) }}
              </ion-badge>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <ion-button
          data-cy="logoutButton"
          name="logoutButton"
          (click)="logoutService.logoutUser()"
          fill="clear">
          Se déconnecter
          <ion-icon slot="end" name="log-out"></ion-icon>
        </ion-button>

        <ion-button
          *ngIf="releases.length > 0"
          data-cy="VersionButton"
          (click)="openChangesModal()"
          fill="clear"
          class="versionButton">
          Version: {{ releases[0].tagName }}</ion-button
        >
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
