//Base imports
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

//Providers
import { AuthProvider } from './auth.provider'
import { serverAddresses } from './server.provider'

//Controllers
import { GlobalController } from '../controllers/global.controller'
import { Socket } from 'ngx-socket-io'
import { HeaderService } from '../services/header.service'

@Injectable({
  providedIn: 'root',
})
export class CommunicationProvider {
  server = serverAddresses.api

  constructor(
    public http: HttpClient,
    public authService: AuthProvider,
    private globalCtrl: GlobalController,
    private readonly socket: Socket,
    private readonly headerService: HeaderService
  ) {}

  getAllPendingCommunications(pending, type) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.server + '/messages?pending=' + pending + '&type=' + type, {
          headers: this.headerService.getCommonHeaders(),
        })
        .subscribe(
          (res: any) => {
            resolve(res)
          },
          err => {
            reject(err)
          }
        )
    })
  }

  getAllConversationsByType(type) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.server + '/conversations?type=' + type, { headers: this.headerService.getCommonHeaders() })
        .subscribe(
          (res: any) => {
            resolve(res)
          },
          err => {
            reject(err)
          }
        )
    })
  }

  getConversationByEmployeeId(id, type, limit, offset) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          this.server + '/conversations/' + id + '?type=' + type + '&limit=' + limit + '&offset=' + offset,
          {
            headers: this.headerService.getCommonHeaders(),
          }
        )
        .subscribe(
          (res: any) => {
            resolve(res)
          },
          err => {
            reject(err)
          }
        )
    })
  }

  getCommunicationById(id) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.server + '/messages/' + id, { headers: this.headerService.getCommonHeaders() })
        .subscribe(
          (res: any) => {
            resolve(res)
          },
          err => {
            reject(err)
          }
        )
    })
  }

  getAllUnseenCommunications(type = null) {
    return new Promise((resolve, reject) => {
      if (type != null) {
        this.http
          .get(this.server + '/messages/unseen?type=' + type, {
            headers: this.headerService.getCommonHeaders(),
          })
          .subscribe(
            (res: any) => {
              resolve(res)
            },
            err => {
              reject(err)
            }
          )
      } else {
        this.http
          .get(this.server + '/messages/unseen', { headers: this.headerService.getCommonHeaders() })
          .subscribe(
            (res: any) => {
              resolve(res)
            },
            err => {
              reject(err)
            }
          )
      }
    })
  }

  addCommunication(communications) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.server + '/messages', communications, { headers: this.headerService.getCommonHeaders() })
        .subscribe(
          (res: any) => {
            resolve(res)
          },
          err => {
            reject(err)
          }
        )
    })
  }

  updateCommunication(communicationId, communication) {
    return new Promise((resolve, reject) => {
      this.http
        .put(this.server + '/messages/' + communicationId, communication, {
          headers: this.headerService.getCommonHeaders(),
        })
        .subscribe(
          (res: any) => {
            resolve(res)
          },
          err => {
            reject(err)
          }
        )
    })
  }

  updateUnseenMessages(employeeId, type) {
    return new Promise((resolve, reject) => {
      this.http
        .put(this.server + '/conversations/' + employeeId + '?type=' + type, employeeId, {
          headers: this.headerService.getCommonHeaders(),
        })
        .subscribe(
          (res: any) => {
            resolve(res)
          },
          err => {
            reject(err)
          }
        )
    })
  }

  deleteCommunication(communicationIds) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(this.server + '/messages', {
          headers: this.headerService.getCommonHeaders(),
          body: communicationIds,
        })
        .subscribe(
          (res: any) => {
            resolve(res)
          },
          err => {
            reject(err)
          }
        )
    })
  }

  sendCommunication(communicationList, pending) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.server + '/send/sms?pending=' + pending,
          { messages: communicationList },
          { headers: this.headerService.getCommonHeaders() }
        )
        .subscribe(
          (res: any) => {
            resolve(res)
          },
          err => {
            reject(err)
          }
        )
    })
  }

  receiveAlerts() {
    return this.socket.fromEvent('new_message')
  }
}
