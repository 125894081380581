<ion-header>
  <ion-toolbar>
    <ion-button (click)="dismiss()" slot="start" fill="clear" data-cy="DismissChangelogButton">
      <ion-icon name="arrow-back-outline"></ion-icon>
    </ion-button>

    <ion-title>Notes des versions</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="changelogInfo">
    <ion-list>
      <ion-text *ngFor="let release of releases" class="changelogElement">
        <ion-label>
          <div class="tag">
            <h1>{{ release.tagName }}</h1>
            <h1>{{ release.releaseDate }}</h1>
          </div>
          <br />
          <div *ngFor="let section of release.body">
            <b>{{ section.title }}</b>
            <ul>
              <li *ngFor="let line of section.content">{{ line }}</li>
            </ul>
          </div>
        </ion-label>
      </ion-text>
    </ion-list>
  </div>
</ion-content>
