import { Injectable } from '@angular/core'
import { AuthProvider } from '../providers/auth.provider'
import { Router } from '@angular/router'
import { GlobalController } from '../controllers/global.controller'

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(
    private authProvider: AuthProvider,
    private router: Router,
    public globalCtrl: GlobalController
  ) {}

  logoutUser() {
    this.authProvider.logout()
    this.router.navigate(['/login'], { replaceUrl: true })
    this.globalCtrl.events.emit('user:logout')
  }
}
