import { Injectable } from '@angular/core'
import { GlobalController } from '../controllers/global.controller'
import { AlertProvider, HoursWorkedEntry } from '../providers/alerts.provider'
import { CommunicationProvider } from '../providers/communication.provider'
import { MESSAGE_TYPES } from 'src/app/utils/constants.utils'

const notificationKeys = {
  [MESSAGE_TYPES.ASSIGNATION]: 'assignationAlerts',
  [MESSAGE_TYPES.GENERAL]: 'generalAlerts',
  [MESSAGE_TYPES.SITES_ALERTS]: 'sitesAlerts',
  [MESSAGE_TYPES.HOUR_ALERTS]: 'hourAlerts',
  [MESSAGE_TYPES.APPLICANTS]: 'applicantsAlerts',
};

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  communicationsAlerts: number = 0
  generalAlerts: number = 0
  assignationAlerts: number = 0
  sitesAlerts: number = 0
  hourAlerts: number = 0
  applicantsAlerts: number = 0

  constructor(
    public readonly globalCtrl: GlobalController,
    private readonly communicationProvider: CommunicationProvider,
    private readonly alertProvider: AlertProvider
  ) { }

  // TODO: Use MessageType instead of string
  // getNotifications(type?: MessageType): number {
    getNotifications(type?: string): number {
      return this[notificationKeys[type] || 'communicationsAlerts'];
    }
    

    setMessagesNotifications(alerts, type) {
      const key = notificationKeys[type];
      if (key) {
        this[key] = alerts;
      }
      this.updateCommunicationsAlerts()
    }

  updateMessageAlerts() {
    Promise.all([
      this.communicationProvider.getAllUnseenCommunications(MESSAGE_TYPES.ASSIGNATION).then((alerts: any) => {
        this.assignationAlerts = alerts
      }),
      this.communicationProvider.getAllUnseenCommunications(MESSAGE_TYPES.GENERAL).then((alerts: any) => {
        this.generalAlerts = alerts
      }),
      this.communicationProvider.getAllUnseenCommunications(MESSAGE_TYPES.APPLICANTS).then((alerts: any) => {
        this.applicantsAlerts = alerts
      }),

    ]).then(() => {
      this.updateCommunicationsAlerts()
    })
  }



  private updateCommunicationsAlerts() {
    this.communicationsAlerts = this.generalAlerts + this.assignationAlerts + this.applicantsAlerts
  }

  updateHourAlerts() {
    this.alertProvider.getHoursWorked().then((res: HoursWorkedEntry[]) => {
      this.hourAlerts = res.length
    })
  }

  updateAlerts(days: number) {
    this.alertProvider.getAlertsNotifications(days).then((res: any) => {
      this.sitesAlerts = res.numberOfAlerts
    })
  }
}
